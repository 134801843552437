import { React, useState } from 'react'
import Input from './Input'
import './OrderForm.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

const OrderForm = () => {
	const [formData, setFormData] = useState({
		fname: '',
		lname: '',
		email: '',
		phone: '',
		cakeType: '',
		cakeTiers: '',
		deliveryDate: '',
		message: '',
		submitted: false,
	})

	const [formErrors, setFormErrors] = useState({
		fnameError: '',
		lnameError: '',
		emailError: '',
		phoneError: '',
		cakeTypeError: '',
		cakeTiersError: '',
		deliveryDateError: '',
		messageError: '',
	})

	// Test Data
	// const [formData, setFormData] = useState({
	// 	fname: 'Milena',
	// 	lname: 'Flynn',
	// 	email: 'milena.flynn@gmail.com',
	// 	phone: '(435) 830-8176',
	// 	cakeType: 'Anniversary',
	// 	cakeTiers: '2',
	// 	deliveryDate: '2023-10-13',
	// 	message: 'Test Submission',
	// 	submitted: false,
	// })

	const normalizeInput = (value, previousValue) => {
		// return nothing if no valued
		if (!value) return value

		// only allows 0-9 inputs
		const currentValue = value.replace(/[^\d]/g, '')
		const cvLength = currentValue.length

		if (!previousValue || value.length > previousValue.length) {
			// returns: "x", "xx", "xxx"
			if (cvLength < 4) return currentValue

			// returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
			if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`

			// returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
			return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`
		}
	}

	const isValidateDate = (dateString) => {
		const dateFormat = /^\d{4}-\d{2}-\d{2}$/
		if (!dateString.match(dateFormat)) return false // Invalid format
		const d = new Date(dateString)
		const dNum = d.getTime()
		if (!dNum && dNum !== 0) return false // NaN value, Invalid date
		return d.toISOString().slice(0, 10) === dateString
	}

	const handleChange = (event) => {
		const { name, value, type } = event.target

		// add css class to lock the label above the inputted text
		event.target.classList.add('active')
		if (!event.target.value) {
			event.target.classList.remove('active')
		}

		if (type === 'tel') {
			setFormData((prevData) => ({ ...prevData, [name]: normalizeInput(value, prevData.phone) }))
		}
		if (type !== 'tel') {
			setFormData((prevData) => ({ ...prevData, [name]: value }))
		}
	}

	const checkValues = (event) => {
		const { name, value, type } = event.target
		const nameFormat = /^[a-zA-Z ]+$/
		const emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
		const phoneFormat =
			/(?:(?:(\s*\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\)?\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/

		let errorMessage = ''

		if (value === '') {
			errorMessage = 'Required'
		}

		if (type === 'text') {
			if (value === '') {
				errorMessage = 'Required'
			} else if (!value.match(nameFormat)) {
				errorMessage = 'Only letters'
			} else if (value.length < 2) {
				errorMessage = '2 letters min'
			} else if (value.length > 45) {
				errorMessage = '45 letters max'
			}
		}

		if (type === 'email') {
			if (value === '') {
				errorMessage = 'Required'
			} else if (!value.match(emailFormat)) {
				errorMessage = 'Invalid email format'
			}
		}

		if (type === 'tel') {
			if (value === '') {
				errorMessage = 'Required'
			} else if (!value.match(phoneFormat)) {
				errorMessage = 'Invalid phone number'
			}
		}

		if (type === 'date') {
			// TODO fix date validation
			if (!isValidateDate(value)) {
				errorMessage = 'invalid date'
			}
		}

		if (type === 'textarea') {
			// TODO create validation and error message for 'message' field.
		}

		setFormErrors((prevData) => ({ ...prevData, [`${name}Error`]: errorMessage }))
	}

	const handleSave = (event) => {
		// prevent the default http/ request on the submitted form
		event.preventDefault()

		// call POST /submit-order to send order details to Milena and Submitter
		fetch('https://api.polishedcakes.com/submit-order', {
			method: 'POST',
			body: JSON.stringify(formData),
			headers: {
				'Content-Type': 'application/json',
				Origin: window.location.hostname,
			},
		})
			.then((res) => res.json())
			.then((data) => {
				console.log(data.orderStatus)
				if (data.orderStatus === 'success') {
					setFormData((prevData) => ({ ...prevData, submitted: true }))
				}
			})
	}
	return (
		<>
			<form id='orderForm' className={formData.submitted ? 'submitted' : ''} onSubmit={handleSave}>
				<h2 id='title'>Cake Request</h2>
				<p id='text'>
					Submit a cake order and Milena will contact you within 24 hours to discuss the details of your personalized
					cake.
				</p>
				<Input
					id='fname'
					display='First Name'
					type='text'
					value={formData.fname}
					error={formErrors.fnameError}
					autoFocus={true}
					handleChange={handleChange}
					checkValues={checkValues}
				/>
				<Input
					id='lname'
					display='Last Name'
					type='text'
					value={formData.lname}
					error={formErrors.lnameError}
					handleChange={handleChange}
					checkValues={checkValues}
				/>
				<Input
					id='email'
					display='Email'
					type='email'
					value={formData.email}
					error={formErrors.emailError}
					handleChange={handleChange}
					checkValues={checkValues}
				/>
				<Input
					id='phone'
					display='Phone Number'
					type='tel'
					value={formData.phone}
					error={formErrors.phoneError}
					handleChange={handleChange}
					checkValues={checkValues}
				/>
				<Input
					id='cakeType'
					display='Cake Type'
					type='select'
					list={['', 'Birthday Cake (adult)', 'Birthday Cake (child)', 'Wedding Cake', 'Anniversary Cake']}
					value={formData.cakeType}
					error={formErrors.cakeTypeError}
					handleChange={handleChange}
					checkValues={checkValues}
				/>
				<Input
					id='cakeTiers'
					display='Tiers'
					type='select'
					list={['', 1, 2, 3]}
					value={formData.cakeTiers}
					error={formErrors.cakeTiersError}
					handleChange={handleChange}
					checkValues={checkValues}
				/>
				<Input
					id='deliveryDate' // TODO use CSS to format colors of date picker to $dark-pink
					display='Delivery Date'
					type='date'
					value={formData.deliveryDate}
					error={formErrors.deliveryDateError}
					min={new Date().toISOString().split('T')[0]} // TODO update date to show in Local Time of browser agent
					handleChange={handleChange}
					checkValues={checkValues}
					// TODO create custom date-picker that shows the calendar automatically upon focus/active
				/>
				<Input
					id='message'
					display='Message'
					type='textarea'
					value={formData.message}
					error={formErrors.messageError}
					handleChange={handleChange}
					checkValues={checkValues}
				/>
				<button id='submit' type='submit'>
					Bake me a cake
				</button>
				{/* TODO add submission validation for  */}
			</form>
			<div className='order-sent'>
				<FontAwesomeIcon icon={faPaperPlane} size='2x' />
				<p>Request Sent</p>
			</div>
		</>
	)
}

export default OrderForm

// webpack enable source-maps
