// Home.js
import React from 'react'
import { Link } from 'react-router-dom'
import './home.css'

function Home() {
	return (
		<div className='hero-container'>
			<h2 className='hero-text'>Welcome to</h2>
			<h1 className='hero-title'>Polished Cakes</h1>
			<Link to='/order'>
				<button className='cta-button'>Order your special cake</button>
			</Link>
		</div>
	)
}

export default Home
