import React from 'react'
import './Input.css'

const Input = ({ id, display, type, value, error, list, min, autoFocus, handleChange, checkValues }) => {
	let inputDiv
	if (type === 'select') {
		const selectItems = list.map((item, i) => {
			return (
				<option key={i} value={item}>
					{item}
				</option>
			)
		})
		inputDiv = (
			<select
				id={`${id}-input`}
				name={id}
				className='input'
				type={type}
				value={value}
				onBlur={(event) => {
					checkValues(event)
				}}
				onChange={(event) => {
					handleChange(event)
				}}
				autoFocus={autoFocus}
				required>
				{selectItems}
			</select>
		)
	} else if (type === 'textarea') {
		inputDiv = (
			<textarea
				id={`${id}-input`}
				name={id}
				className='input'
				type={type}
				value={value}
				onBlur={(event) => {
					checkValues(event)
				}}
				onChange={(event) => {
					handleChange(event)
				}}
				autoFocus={autoFocus}
				rows='3'
				wrap='hard'
				required
			/>
		)
	} else {
		inputDiv = (
			<input
				id={`${id}-input`}
				name={id}
				className='input'
				type={type}
				value={value}
				onBlur={(event) => {
					checkValues(event)
				}}
				onChange={(event) => {
					handleChange(event)
				}}
				autoFocus={autoFocus}
				min={min}
				required
			/>
		)
	}

	return (
		<div id={id} className={error ? 'input-box invalid' : 'input-box'}>
			{inputDiv}
			<label id={`${id}-label`} className='input-label' htmlFor={`${id}-input`}>
				{display}
			</label>
			{/* TODO add switch case for each input type and add inputDiv variable here */}
			<span id={`${id}-error`} className='error-message'>
				{error}
			</span>
		</div>
	) // TODO add custom validation to each input element and use or remove the 'error' span
}

export default Input
