// About.js
import React from 'react'

function About() {
	return (
		<div>
			<p>
				Hi! My name is Milena (Me-lay-na). I am from Chodziez Poland where I learned to bake delicious artisan cakes
				from fresh, organic ingredients with my Babcia and Mama.{' '}
			</p>
		</div>
	)
}
export default About
