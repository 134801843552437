import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons'

import Home from './home/home'
import About from './about/about'
import OrderForm from './order/OrderForm'
import logo from '../components/assets/polished-cakes.png'
import './App.css'

const App = () => {
	return (
		<Router>
			<div className='App'>
				<header>
					<Link className='title-wrapper' to='/'>
						<div className='title-container'>
							<h2 className='title'>Polished</h2>
							<img src={logo} alt='small-slide-of-cake' className='logo' />
							<h2 className='title'>Cakes</h2>
						</div>
						{/* <p className='subtext'>Personalized. Polish. Perfections.</p> */}
						<p className='subtext'>By Milena Flynn</p>
					</Link>
				</header>
				<div id='content'>
					<Routes>
						<Route exact path='/' element={<Home />}></Route>
						<Route exact path='/about' element={<About />}></Route>
						<Route exact path='/order' element={<OrderForm />}></Route>
						<Route exact path='*' element={<Home />}></Route>
					</Routes>
				</div>
				<footer>
					<div className='social'>
						<a href='https://instagram.com/polishedcakes' target='_blank' rel='noreferrer'>
							<FontAwesomeIcon icon={faInstagram} size='2x' />
						</a>
						<a href='https://facebook.com' target='_blank' rel='noreferrer'>
							<FontAwesomeIcon icon={faFacebook} size='2x' />
						</a>
						<a href='https://github.com/Flynnin/polished-cakes' target='_blank' rel='noreferrer'>
							<FontAwesomeIcon icon={faGithub} size='2x' />
						</a>
					</div>
				</footer>
			</div>
		</Router>
	)
}

export default App

// picturesque, pure, priceless, prized, pristine,
